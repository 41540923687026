/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, bool, string, shape,
} from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import { compose } from 'recompose';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import loadable from '@loadable/component';

import { trackModule } from '../../../helpers/tracking/common/commonTrackingHelpers';
import findURL from '../../../gql/queries/findURL';
import { GRAPHQL_ENV } from '../../../gql';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getFeatureFlags, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getSCILoadingState } from '../../../../state/ducks/SCI/SCI-Selectors';
import CoreBrowsingRedirectContainer from './CoreBrowsingRedirectContainer';
import PriceBreakDownContext from './PriceBreakDownContext';
import useSalesforcePersonalizedContentQuery from '../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const LoadableCoreBrowsingBuilder = loadable(() => import(/* webpackChunkName: "CoreBrowsingBuilder" */ './CoreBrowsingBuilder'));
const LoadableGraphqlSearchContainer = loadable(() => import(/* webpackChunkName: "GraphqlSearchContainer" */ '../GraphqlSearchPage/GraphqlSearchContainer'));

/*
    Component Intent
        - This component's job is to take a path, vanity url, or bannercode and begin page determination
        - The priority for findURL is vanity > bannercode > path
        - When the findURL call is made we recieve a type back
        - That type will tell us what page to build and query to run next

        - There should most likely be 2 fallbacks:
            * if a pathname has no type returned, run a Search query
            * if no pathname, or a totally bogus one, revert to HomePage
*/

const CoreBrowsingContainer = ({
    location: { pathname, hash }, brand, ffIsRedirectTestingEnabled, isBot = false, is404, productUnavailableName, SCIIsLoading, whichUrlRedirect, ffIsPriceBreakDownEnabled, ffIsPriceBreakDownContextEnabled,
}) => {
    // after initial search findURL fails to run again
    // below if statement checks if 'searchterm' exists in url before calling find URL
    // if exists it will call search container
    if (pathname.includes('searchterm')) {
        const pathnameArr = pathname.split('/');
        const searchTerm = pathnameArr[2];
        return <LoadableGraphqlSearchContainer key={`search-${pathname}`} brand={brand} searchTerm={searchTerm} />;
    }
    // AB TEST for CA pricing
    const { data: priceBreakDown, loading: priceBreakDownLoading, variables } = useSalesforcePersonalizedContentQuery({
        queryName: 'PriceBreakDown',
        interactionName: `${brand?.domain} - Get Campaign - Total Price for CA`,
        featureFlag: ffIsPriceBreakDownEnabled, // ffIsPriceBreakDownEnabled
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });
    const dispatch = useDispatch();
    const priceBreakDownABTest = priceBreakDown?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup || 'Control';
    const caPriceBreakDownVariant = priceBreakDownABTest?.toLowerCase() === 'test';

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: priceBreakDown?.contentSF,
        isFireImpression: true,
        page: 'category',
    });

    useEffect(() => {
        if (priceBreakDown && priceBreakDownABTest) {
            const caPriceBreakDownValue = sessionStorage.getItem('caPriceBreakDown');
            if (!caPriceBreakDownValue) {
                dispatch(trackEvent({
                    eventCategory: 'Experiment',
                    eventAction: 'CA Total vs Merch',
                    eventLabel: caPriceBreakDownVariant ? 'Variant' : 'Control',
                    feature_detail: caPriceBreakDownVariant ? 'Variant' : 'Control',
                    feature_element: 'CA Total vs Merch',
                    feature_category: 'Experiment',
                }));
                sessionStorage.setItem('caPriceBreakDown', true);
            }
        }
    }, [priceBreakDown, priceBreakDownABTest]);

    // on page transition, bring user back to top of page - currently not working on back nav when cached
    useEffect(() => {
        if (typeof window !== 'undefined' && hash === '') {
            window.scroll(0, 0);
        }
    }, [pathname]);

    let newPathName;
    if (pathname.includes(' ') || pathname.includes('.')) {
        newPathName = encodeURI(pathname);
    }
    if (pathname.includes("'")) {
        newPathName = encodeURI(pathname).replace(/'/g, '%27');
    }
    if (pathname.includes('&')) {
        newPathName = encodeURI(pathname).replace(/&/g, 'and');
    }

    const FIND_URL_QUERY = findURL(brand.domain, newPathName || pathname);
    const { loading, data, error } = useQuery(FIND_URL_QUERY);

    if (loading && !SCIIsLoading) {
        return null;
    }

    if (error || !data) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FIND_URL_QUERY,
            component: 'CoreBrowsingContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    const pageData = data.findURL;

    if (mbpUtil.getEnv('REACT_APP_MBP_LOGGER_CONSOLE') === 'DEBUG') {
        console.log('CoreBrowsingContainer pageData', pageData);
    }

    const hasPathRedirectEnabled = () => {
        if (whichUrlRedirect?.includedPathList) {
            const redirectConfig =  (whichUrlRedirect?.includedPathList || [])?.find?.((item) => item?.url === pathname) || null;
            return {
                isRedirectEnabled: !!redirectConfig,
                transition: redirectConfig?.transition || whichUrlRedirect?.transition || 'siteLoader',
            };
        }
        return {
            isRedirectEnabled: pathname === whichUrlRedirect?.url || false,
            transition: whichUrlRedirect?.transition || 'siteLoader',
        };
    };

    const pathRedirectRender = hasPathRedirectEnabled();
    if (ffIsRedirectTestingEnabled
        && !isBot
        && pathRedirectRender.isRedirectEnabled
    ) {
        mbpLogger.logDebug({ appName: process.env.npm_package_name, message: 'CoreBrowsingContainer ... redirecting' });
        return (
            ffIsPriceBreakDownContextEnabled
                ? (
                    <PriceBreakDownContext.Provider value={{ priceBreakDownABTest }}>
                        <CoreBrowsingRedirectContainer
                            pathname={pathname}
                            page={pageData}
                            brand={brand}
                            ffIsRedirectTestingEnabled={ffIsRedirectTestingEnabled}
                            transition={pathRedirectRender.transition}
                        />
                    </PriceBreakDownContext.Provider>
                )
                : (
                    <CoreBrowsingRedirectContainer
                        pathname={pathname}
                        page={pageData}
                        brand={brand}
                        ffIsRedirectTestingEnabled={ffIsRedirectTestingEnabled}
                        transition={pathRedirectRender.transition}
                    />
                )
        );
    }

    mbpLogger.logDebug({ appName: process.env.npm_package_name, message: `CoreBrowsingContainer ... sending to CoreBrowsingBuilder.  is404? ${is404}` });
    return (
        ffIsPriceBreakDownContextEnabled
            ? (
                <PriceBreakDownContext.Provider value={{ priceBreakDownABTest }}>
                    <LoadableCoreBrowsingBuilder
                        brand={brand}
                        pageData={pageData}
                        is404={is404}
                        productUnavailableName={productUnavailableName}
                    />
                </PriceBreakDownContext.Provider>
            )
            : (
                <LoadableCoreBrowsingBuilder
                    brand={brand}
                    pageData={pageData}
                    is404={is404}
                    productUnavailableName={productUnavailableName}
                />
            )
    );
};

CoreBrowsingContainer.propTypes = {
    location: object.isRequired,
    brand: object.isRequired,
    ffIsRedirectTestingEnabled: bool.isRequired,
    ffIsPriceBreakDownEnabled: bool.isRequired,
    ffIsPriceBreakDownContextEnabled: bool.isRequired,
    isBot: bool.isRequired,
    is404: bool,
    productUnavailableName: string,
    SCIIsLoading: bool,
    whichUrlRedirect: shape({
        transition: string,
        url: string,
    }),
};

CoreBrowsingContainer.defaultProps = {
    is404: false,
    productUnavailableName: '',
    SCIIsLoading: false,
    whichUrlRedirect: {
        transition: '',
        url: '',
    },
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    featureFlags: getFeatureFlags(state),
    ffIsRedirectTestingEnabled: getFeatureFlag('is-redirect-testing-enabled')(state),
    whichUrlRedirect: getFeatureFlag('which-redirect-test-1-variant')(state),
    ffIsPriceBreakDownEnabled: getFeatureFlag('is-total-price-for-ca')(state),
    ffIsPriceBreakDownContextEnabled: getFeatureFlag('is-price-break-down-context-enabled')(state),
    isBot: getIsBot(state),
    SCIIsLoading: getSCILoadingState(state),
});

const enhance = compose(
    trackModule('CoreBrowsing'),
    withRouter,
    connect(mapStateToProps),
);

export default enhance(CoreBrowsingContainer);
